<template>
  <div class="common-page" >
     <slot name="content"></slot>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {};
  }
};
</script>
<style lang='less' scoped>
.common-page{
  width: 410px;
  margin:20px auto 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);}
@media (max-width: 767px) {
}
</style>
