<template>
  <div id="getRoc">
    <goBack></goBack>
    <broadPage>
      <div slot="content">
        <div class="content">
          <div class="tab-change">
            <div
              v-bind:key="index"
              class="text-center tab-title"
              v-for="(item, index) of list"
              :class="{ active: selected == index }"
              @click="changeColor(index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="box-padding">
            <!-- from -->
            <div class="get-content">
              <p>From</p>
              <div class="border-contnet">
                <el-row>
                  <el-col :span="10">
                    <div class="border-rignt">
                      <div class="item-left">
                        <img src="./images/balances.svg" />
                        <span>roUSD</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14">
                    <div class="item-right">
                      <div>
                        <span>Balance:</span>
                        <span>1005.499567</span>
                      </div>
                      <el-input
                        v-model="coinFrom"
                        placeholder="0.00"
                        class="noBorder"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- to -->
            <div class="get-content">
              <p>To</p>
              <div class="border-contnet">
                <el-row>
                  <el-col :span="10">
                    <div class="border-rignt">
                      <div class="item-left">
                        <img src="./images/balances.svg" />
                        <span>ROC</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="14">
                    <div class="item-right">
                      <div>
                        <span>Balance:</span>
                        <span>1005.499567</span>
                      </div>
                      <el-input
                        v-model="coinTo"
                        placeholder="0.00"
                        class="noBorder"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <router-link
              to="/getRoc"
              class="btn btn-middle btn-primary mt-2 mb-3"
              >Trade</router-link
            >
            <!-- price -->
            <div class="price">
              <el-row type="flex" justify="space-between">
                <el-col>
                  <p>Price</p>
                </el-col>
                <el-col class="text-right">
                  <span>1</span>
                  <span> ROC</span>
                  <span> = </span>
                  <span>1.02</span>
                  <span> roUSD</span>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between" class="mt-2 mb-2">
                <el-col>
                  <i>Slippage Tolerance
                    <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top">
                      <i class="fa fa-question-circle"></i>
                    </el-tooltip>
                <!-- <i class="fa fa-question-circle"></i> -->
                  </i>
                </el-col>
                <el-col class="text-right">
                  <el-input-number
                    size="mini"
                    @change="handleChange"
                    :min="1"
                    :max="100"
                    v-model="num"
                  ></el-input-number>
                  <div class="number-unit">%</div>
                </el-col>
              </el-row>
              <el-row type="flex" justify="space-between" class="mt-2 mb-2">
                <el-col>
                  <p>
                    Transaction Deadline<i class="fa fa-question-circle"></i>
                  </p>
                </el-col>
                <el-col class="text-right">
                  <el-input-number
                    size="mini"
                    @change="handleChange2"
                    :min="1"
                    v-model="num2"
                  ></el-input-number>
                  <div class="number-unit">min</div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </broadPage>
  </div>
</template>

<script>
import broadPage from "../../components/broadPage.vue";
import goBack from "../../components/goBack.vue";
export default {
  name: "getRoc",
  data() {
    return {
      num: 1,
      num2: 20,
      coinFrom: "",
      coinTo: "",
      selected: 0,
      list: [
        {
          title: "Get roUSD",
        },
        {
          title: "Sell roUSD",
        },
      ],
    };
  },
  components: { broadPage, goBack },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleChange2(value) {
      console.log(value);
    },
    changeColor(index) {
      this.selected = index;
      console.log(index);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#getRoc {
  .noBorder /deep/ .el-input__inner {
    border: 0 !important;
    height: 32px;
    line-height: 32px;
    padding: 0 15px 0 0px;
    padding-right: 0;
    font-size: 20px;
    color: #828282 !important;
    background: #f6f8fb;
    &:focus {
      box-shadow: none !important;
    }
  }
  .tab-change {
    display: flex;
    justify-content: space-between;
  }
  .tab-title {
    height: 60px;
    line-height: 60px;
    width: 50%;
    font-family: ParmaPetit;
    font-size: 24px;
    background: #f2f2f2;
    color: #828282;
    cursor: pointer;
    border: 1px solid #f2f2f2;
  }
  .active {
    background-color: white;
    color: #085da3;
    border: 1px solid white;
  }
  .box-padding {
    padding: 30px;
  }
  .content {
    // padding: 0px 30px 30px 30px;
    .get-title {
      font-family: ParmaPetit;
      font-size: 24px;
      color: #085da3;
      margin-bottom: 30px;
    }
    .get-content {
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
        margin-bottom: 15px;
      }
      .border-contnet {
        border: 1px solid #e0e0e0;
        margin-bottom: 18px;
        .border-rignt {
          border-right: 1px solid #e0e0e0;
        }
        .item-left {
          vertical-align: middle;
          display: inline-block;
          padding: 25px 0px 25px 20px;
          img {
            margin-top: -6px;
          }
          span {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #085da3;
            margin-left: 10px;
          }
        }
        .item-right {
          background-color: #f6f8fb;
          padding: 12px 20px;
          height: 80px;
          span {
            font-family: Louis George Cafe;
            font-size: 14px;
            line-height: 140%;
            color: #828282;
          }
          p {
            font-family: Louis George Cafe;
            font-size: 20px;
            color: #828282;
          }
        }
      }
    }
    .btn {
      width: 100%;
    }
    .price {
      position: relative;
      p {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #828282;
      }
      span {
        font-family: Louis George Cafe;
        font-size: 16px;
        color: #4f4f4f;
      }
      .number-unit {
        font-size: 14px;
        color: #4f4f4f;
        position: absolute;
        right: 30px;
        top: 3px;
      }
    }
    /deep/.el-input-number--mini .el-input__inner {
      padding-right: 56px;
    }
  }
}
</style>
