<template>
  <div class="goback">
    <span @click="goback"> <i class="fa fa-chevron-left"></i>Back</span>
  </div>
</template>
<script>
export default {
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.goback {
  width: 410px;
  margin: 140px auto 0px;
  span {
    text-align: left;
    display: block;
    font-family: Louis George Cafe;
    font-size: 14px;
    line-height: 140%;
    color: #085da3;
    cursor: pointer;
    i {
      margin-right: 8px;
      color: #085da3;
    }
  }
}
</style>
